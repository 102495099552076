.add_item_outer_card_styling {
  background-color: #ffffff;
}

.add_item_loading_text {
  margin-top: 2em;
  text-align: center;
}

.add_item_container {
}

.add_item_title {
  border-bottom: 0.5px solid lightgrey;
}

.add_item_title_content {
  margin: 0.5em 0em 0.5em 1em;
  color: #343434;
  font-weight: bolder;
  font-size: 1.5em;
}

.add_item_cancel_link {
  cursor: pointer;
  color: #166ed5;
  background-color: #ffffff;
  font-size: 1.2em;
  margin-left: 1em;
  cursor: pointer;
}

.add_item_cancel_text {
  width: 10px;
  vertical-align: baseline;
}

.add_item_form_container {
  margin-top: 2em;
  display: flex;
  place-content: space-evenly;
}

.add_item_form_container_asset {
  margin-top: 2em;
  display: flex;
  justify-content: space-evenly;
}

.form_submit_button_container {
  margin-top: 2em;
  text-align: center;
}

.add_part_table_container {
  width: 50em;
}

.upload_csv_container {
  margin-top: 2em;
  margin-left: 2em;
}

.upload_csv_text {
  font-size: 1.2em;
}

.upload_csv_sub_text {
  margin-top: 1.3em;
  font-size: 0.8em;
  font-style: italic;
}
