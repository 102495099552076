.rule-configuration-section>.title {
  padding: 1em 0 1em 1.2em;
  color: #343434;
}

.rule-configuration-section>.title>h2 {
  font-weight: 900;
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab {
  border-top: 0.003em #CDCDCD solid;
  min-height: 15em;
  padding: 0;
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab>.tabbedSectionComponent-tabs {
  flex-basis: 30%;
  display: flex;
  margin-left: 0;
  margin-top: 0;
  border-right: .003em #CDCDCD solid;
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab>.tabbedSectionComponent-tabs>.tab-with-control-component.tab {
  justify-content: space-between;
  border-radius: 0;
  border-width: 0 0.05em 0.05em 0;
  border-style: solid;
  border-color: #CDCDCD;
  padding-top: 1.5em;
  padding-bottom: 1.5rem;
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab>.tabbedSectionComponent-tabs>.tab-with-control-component.tab.active {
  border-right: 0;
  background-color: unset;
  color: #343434;
  /* text-decoration: underline; */
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab>.tabbedSectionComponent-content {
  margin: 0;
  padding: 1em 2em 2em 2em;
  box-shadow: unset;
  border: none;
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab>.tabbedSectionComponent-content .tabbedSectionComponent-contentTitle {
  font-size: 1.7em;
  font-weight: bold;
  color: #343434;
  margin-bottom: 0.5rem;
}

.rule-configuration-section>.tabbedSectionComponent-container.rule-detail-tab>.tabbedSectionComponent-content .note {
  margin-bottom: 1em;
  color: #343434
}

.rule-configuration-section>.notes-tips {
  display: flex;
  padding: 1em 0 0 2em;
  color: #343434;
}

/* .note class to get rid of the small black border on the left of note
* in LowStockRuleContent coming from in-built .note class of mdb library
*/

.note {
  padding: 0px;
  border-left: 0px;
}

.rule-configuration-section>.notes-tips.hidden {
  display: none;
  padding: 1em 0 0 2em;
}

.rule-configuration-section>.notes-tips>a {
  color: #166ED5;
}