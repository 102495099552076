.container {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.left_component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}
.right_component {
  width: 55%;
  margin-left: 20px;
}

@media (max-width: 1050px) {
  .container {
    min-width: 550px;
    display: flex;
    flex-direction: column;
  }
  .left_component,
  .right_component {
    width: 100%;
    margin: 10px;
    padding: 10px;
  }
}
