.search_card_body {
  padding: 0;
  margin-top: -10px;
  font-size: 14px;
}

.search_card_flags_results {
  text-align: right;
  font-size: 18px;
}

.suggestion_title {
  font-size: 20px;
}

.red_text {
  color: #f62227;
}

.orange_text {
  color: #e56513;
}

.green_text {
  color: #4b9910;
}

.grey_text {
  color: grey;
}

.search_suggestion_card {
  box-shadow: none;
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  background-color: rgba(0, 0, 0, 0);
}

.cardTitle {
  float: left;
}

.search_results_overlay {
  height: 100%;
  overflow: auto;
  width: auto;
  box-shadow: none;
  border-radius: 10px;
}

.no_results_overlay {
  height: 100%;
  overflow: auto;
  width: auto;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.inside_suggestion_card {
  padding: 2%;
  align-content: left;
  color: black;
  text-align: left;
  text-decoration: none;
  box-shadow: none;
}

.auto_suggest_container {
  width: 100%;
  height: 50px;
}

.auto_suggest_input {
  width: 100%;
  height: 90%;
  border-radius: 0.3em;
  border: 1px solid #979799;
  padding-left: 2%;
  vertical-align: text-top;
}

.auto_suggest_highlight_background {
  background-color: #ededed;
}

.auto_suggest_list_style {
  list-style-type: none;
  padding-left: 0;
  background-color: white;
}

.auto_suggest_suggestion_container {
  height: 400px;
  box-shadow: 0px 0px 20px 15px rgba(0, 0, 0, 0.3);
  background-color: white;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) {
  #search-form {
    font-size: 1rem;
    height: 2.4rem;
  }
}

.search_results_div {
  position: fixed;
  z-index: 2;
}
