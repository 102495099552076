.icon_button {
  min-width: 40px;
  min-height: 40px;
  font-size: 24px;
  padding: 4px;
}
.drop_down_toggle {
  width: 40px;
  height: 40px;
  padding: 0px;
  background-color: white !important;
  color: black;
  margin: 0px;
  margin-left: 15px !important;
  margin-right: 10px !important;
  box-shadow: none;
}

.drop_down_toggle:hover,
.drop_down_toggle:focus {
  box-shadow: none !important;
}

.icon_button:hover {
  color: white;
  background-color: #166ed5;
}
