.loading {
  text-align: center;
  width: 400px;
}

.title-text {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
  padding: 0;
}
.my-pretty-chart-container {
  padding: 0;
  height: 100%;
  width: 400px;
  display: flex;
  margin: 0 auto;
}

@media only screen and (max-width: 1380px) {
  .title-text {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    padding: 0;
  }
  .my-pretty-chart-container {
    padding: 0;
    height: 100%;
    width: 400px;
    display: flex;
    margin: 0 auto;
  }
}
