.image {
  width: 70%;
  border: none;
}

.header_style {
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgray;
  font-weight: bolder;
  line-height: 1.5;
  font-size: 18px;
  margin-bottom: 20px;
}

.group {
  display: flex;
  margin-bottom: 10px;
}

.label_group {
  text-align: center;
  width: 100%;
  height: 90px;
  margin: 5px;
  padding: 10px;
}

.value {
  font-size: 30px;
}

.backlog_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.backlog_title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 700;
}

.right_container {
}
