.side_navigation_container {
  border: none;
  background-color: white;
  position: fixed;
  top: 60px;
  left: 0px;
  width: 200px;
  height: 100%;
  padding-top: 15px;
}

.main_app_component_container {
  position: relative;
  height: calc(100% - 70px);
  width: calc(100% - 200px);
  left: 200px;
  top: 70px;
  overflow-y: scroll;
  box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.header_component_container {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  background-color: white;
  z-index: 1;
}

.render_component_container {
  background-color: transparent;
  padding: 20px;
}
