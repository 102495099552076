.filter_tab_style {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: Black;
  font-size: 0.9em;
  margin-top: 2%;
  width: 90%;
}

.selected_tab_style {
  background-color: #166ed5;
  color: white;
}

.not_selected_tab_style:hover {
  background-color: #efefef;
}

.tab_list {
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.groupby_label {
  font-weight: bold;
  color: #c6cbd4;
}

.tabs {
  display: inline-block;
  flex-direction: column;
  margin-left: 6px;
}
