.content_holder {
  height: auto;
  background-color: white;
  vertical-align: top;
}

.icon_button {
  min-width: 40px;
  min-height: 40px;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}

.flex_direction_column {
  display: inline-flex;
  flex-direction: column;
}

.navigation_button {
  width: 100px;
  padding: 5px;
  align-self: center;
  letter-spacing: 1px;
  color: #166ed5;
  cursor: pointer;
  text-align: center;
  border-radius: 3px;
  font-size: 22px;
  display: inline;
}

.navigation_button_end {
  width: 100px;
  padding: 5px;
  align-self: center;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 3px;
  font-size: 22px;
  font-weight: bold;
  display: inline;
}

.side_bar {
  padding: 5px 15px 5px 5px;
  margin: 5px;
  display: inline-flex;
  flex-direction: column;
  border-right: 2px solid lightgray;
}
.chart {
  width: 100%;
  align-self: center;

  display: inline-block;
  vertical-align: top;
  margin-top: 2%;
  padding: 0 0 0 10%;
}

.data_view {
  margin: 10px 5px 10px 5px;
  padding: 5px;
  width: 100%;
}

.override_padding {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 13%;
  display: flex;
  flex-direction: column;
}

.top_component {
  text-align: center;
  display: inline-block;
  margin: 0px 20px 5px 20px;
  border-bottom: 2px solid lightgray;
  padding: 5px;
  align-content: center;
}

.bottom_component {
  min-height: 600px;
  height: 100%;
  display: inline-block;
  margin: 5px 20px 20px 20px;
  padding: 5px;
  align-content: center;
  display: flex;
  flex-direction: row;
}
.lds-roller {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 20% auto auto auto;
}

.line {
  position: static;
  background-color: #eee;
  border: 0 none;
  color: #eee;
  height: 2px;
  margin: 0 auto;
  width: 100%;
  top: 46%;
  right: 50;
  left: 0;
  margin-bottom: 1%;
}

.grid_view {
  height: 100%;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.navigation_path {
  padding-left: 30px;
}

@media only screen and (max-width: 1380px) {
  .overview {
    position: block;
    width: 100%;
    margin: 0;
  }

  .order_backlog_chart {
    margin: 0 auto;
    margin-top: 5%;
    display: block;
  }
}

.graph_title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: bold;
}

.image {
  height: 300px;
}

@media only screen and (max-width: 768px) {
  .content_holder {
    margin-top: 15%;
  }
}
