.back-button {
  font-size: 1.3em;
  font-weight: bold;
  margin: 2%;
  margin-left: 4%;
}

#search-form {
  margin-bottom: 0.6%;
  width: 11em;
}

.btn.default-button.add {
  color: #ffffff;
}

.add {
  float: right;
}

.add-user-button {
  float: none;
  width: 100%;
}

.user-search-bar {
  display: inline-block;
  margin-bottom: 2%;
  width: 50%;
}

.level-view-items {
  margin-bottom: 1.2em;
}

.user-view-active {
  background: #fafafa;
}

.users-table {
  width: 100%;
}

.margin_maker2 {
  margin-top: 15%;
}

.users-title {
  text-align: left;
  font-size: 1.4em;
  font-weight: bold;
  color: #343434;
  margin: 2% 2% 1% 6%;
}

.users-description {
  color: #343434;
  font-size: 1em;
  font-style: italic;
  font-weight: 400;
  margin-left: 2%;
  text-align: left;
}

.add-user {
  display: block;
  margin: 0 auto;
  margin-bottom: 5%;
  width: 80%;
}

.users-list {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
}

.users-list-title {
  color: #343434;
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 4%;
  margin-top: 2.5%;
  text-align: left;
}

.users-header {
  background-color: white;
  border-bottom: 1pt solid #eaeaea;
  color: #343434;
  padding-left: 2%;
  padding-right: 2%;
}

.users-header-username,
.users-header-lastName,
.users-header-firstName,
.users-header-email {
  font-size: 1.3em;
  font-weight: bold;
}

.users-header-username {
  width: 35%;
}

.users-header-lastName,
.users-header-firstName {
  width: 30%;
}

.users-header-deleteButton {
  border-bottom: 1pt solid #eaeaea;
  font-size: 1.3em;
  font-weight: bold;
}

.user-row {
  border-bottom: 1pt solid #eaeaea;
  height: 50px;
}

.user-row > td {
  font-size: 0.7em;
}

.deleteButton {
  color: #f62227;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .margin_maker2 {
    margin-top: 6%;
  }
  #search-form {
    width: 60%;
  }
  .default-button {
    font-size: 1em;
  }
  .users-title {
    font-size: 1.8em;
    margin: 3% 2% 1% 5%;
  }
  .add-user {
    width: 30em;
  }
  .user-row > td {
    font-size: 0.9em;
  }
}
