.tracking_departments_links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 10px;
  flex-grow: 1;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.tracking_links_header {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  background-color: #166ed5;
  color: #ffffff;
  padding: 5px;
  font-size: 0.9em;
}
.tracking_departments_links_detail {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 10px;
  padding-right: 10px;
}

.tracking_department_link_row {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  background-color: #ffffff;
  color: #4c4c4c;
  border-bottom: 1pt solid #f6f6f6;
  padding-top: 5px;
  padding-bottom: 5px;
}

.time_duration {
  font-size: 0.9em;
  flex: 0.2;
  text-align: left;
}

.time_detail {
  text-align: left;
  flex: 0.2;
}
