.home_icon_container {
  border-bottom: 1px solid #d3d3d3;
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_image_style {
  width: 130px;
}

.search_bar_container {
  border-left: solid;
  border-right: solid;
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgrey;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 0.85;
}

.search_bar {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.user_actions_container {
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgrey;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  flex: 0.15;
  padding: 0em 1em 0em 1em;
}

.user_actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile,
.profile:hover {
  color: grey;
}

.profile:after {
  margin-left: 0.75em;
  vertical-align: middle;
  display: inline-block;
}

.profile_name {
  display: inline-block;
  color: black;
}

.logout {
  color: red;
}
