.single_card_container {
  height: 300px;
}
.progress_bar_container {
  height: 60%;
}

.card_container {
  vertical-align: top;
  padding: 10px;
  width: 340px;
  max-width: 340px;
  min-width: 340px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: 0.3s;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 10px;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  text-align: center;
  height: 240px;
}

.card_container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  color: black;
}

.top {
  display: flex;
  flex-direction: row;
}

.progress_bar_line {
  position: relative;
  top: 45%;
}

.horizontal_line2 {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress_bar_bottom {
  top: 43%;
  position: relative;
}

.bottom {
  margin-right: 0.5em;
  width: 100%;
  font-size: 0.65em;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: space-between;
}

.flex_direction_row {
  display: flex;
  flex-direction: row;
}
