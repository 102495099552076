.create_button {
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  border: none;
  border-radius: 0.15625rem 0.15625rem 0.15625rem 0.15625rem;
  cursor: pointer;
  color: white;
  font-size: 0.9em;
  margin-bottom: 10px;
  width: 100%;
  background-color: #166ed5;
  display: block;
}

.create_button:hover {
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  color: white;
}

.card_body {
  height: 100%;
  width: 100%;
  background-color: white;
}

.seperator {
  margin: 15px 5px 15px 5px;
  border: 1px solid lightgray;
}

.seperator_vertical {
  height: auto;
  margin: 0px 15px 0px 15px;
}

.bottom_component_style {
  height: 100%;
  min-height: 600px;
  width: 100%;
  display: flex;
  padding: 5px;
}

.data_view_container {
  width: 100%;
}

.data_view_group {
  width: 100%;
}
