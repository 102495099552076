.actions_container {
  display: flex;
}
.grid_card_container_style {
  width: 400px;
  height: 220px;
  margin: 10px;
}

.filter_bar {
  height: 40px;
  border-radius: 3px;
  width: 100%;
  border: 1px solid #c9c9c9;
  padding-left: 1em;
}

.icon_button {
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px;
}

.icon_button:hover {
  color: white;
  background-color: #166ed5;
}
