.grid_view {
  display: inline-block;
  margin-top: 10px;
  width: 100%;
  padding-left: 1%;
}

.icon_button {
  min-width: 40px;
  min-height: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  cursor: pointer;
  padding: 4px;
}

.icon_button:hover {
  color: white;
  background-color: #166ed5;
}

.filter_group {
  padding: 5px;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}

.filter_bar {
  height: 40px;
  margin-left: 5px;
  margin-right: 10px;
  width: 90%;
}

.empty_data_message {
  padding: 70px;
  color: gray;
  text-align: center;
}
