.metrics_detail_group {
  text-align: center;
  width: 250px;
  height: 90px;
  margin: 5px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
}

.metrics_category {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 10px;
}

.metrics_group {
  display: inline-flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
}

.metrics_category_label {
  font-size: 18px;
  font-weight: bold;
  margin: 5px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 3px;
  text-align: left;
}

.metrics_label {
  font-size: 12px;
}

.metrics_value {
  font-weight: bold;
  font-size: 24px;
}

.value_with_unit_group {
  display: inline-flex;
  flex-direction: row;
}

.unit {
  margin-left: 5px;
}
