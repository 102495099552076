.tab_list_style {
  margin-left: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: grey;
}

.tab_style {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  text-align: left;
  background-color: transparent;
  text-transform: none;
  letter-spacing: 0.03rem;
  padding-left: 25px;
  height: 40px;
  border: none;
  border-color: transparent;
  cursor: pointer;
  color: grey;
  font-size: 0.95em;
  margin-top: 5%;
  width: 100%;
  align-items: center;
}

.tab_style:focus {
  outline: none; 
}

.tab_style:hover {
  background-color: #efefef;
}

.focused_tab_style::before {
  content: '';
  position: relative;
  color: transparent;
  top: -2px;
  left: -20px;
  background-color: transparent;
  height: 40px;
  border: none;
  border-right: 4px solid #166ed5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  align-items: center;
}

.focused_tab_style {
  border-color: #166ed5;
  color: #166ed5;
  padding-left: 20px;
  font-weight: bold;
}
